import React, { ReactNode } from 'react';
import { Grid } from '@mui/material';
import Header from './header';
import Sidebar from './sidebar';

type Layout = {
  children: ReactNode
};

const layout = ({ children }: Layout) => (
  <Grid container>
    <Grid item xs={12} md={12}>
      <Header />
    </Grid>
    <Grid item md={1} sm={1} xs={1} lg={0.5}>
      <Sidebar />
    </Grid>
    <Grid
      container
      item
      md={11}
      sm={11}
      xs={11}
      lg={11.5}
      sx={{
        position: 'relative',
        top: '70px',
        padding: '24px 24px 24px 35px',
        background: '#EEEEEE',
        minHeight: 'calc(100vh - 70px)',
        height: 'auto',
      }}
    >
      {children}
    </Grid>
  </Grid>
);

export default layout;
