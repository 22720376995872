import React from 'react';
import { ImageProps } from './types';

const Image = (props: ImageProps) => {
  const { alt, src, ...rest } = props;
  return (
    <img src={src} alt={alt} {...rest} />
  );
};

export default Image;
