import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { IToastProps } from './type';

const Toast = (props: IToastProps) => {
//   const [open, setOpen] = React.useState(false);
  const {
    open, message, severity, onClose,
  } = props;
  return (
    <Snackbar
      sx={{ width: '20%' }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <Alert severity={severity} sx={{ width: '100%', padding: '15px' }} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
