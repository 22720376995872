// import React from 'react';

export enum Severity {
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error',
}

export interface IToastProps {
  open: boolean;
  message: string;
  severity: Severity;
  onClose?: any;
}
