import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormLabel, Stack, Switch } from '@mui/material';
import { IAccordionProps } from './type';

const AccordionTree = (props: IAccordionProps) => {
  const { modules, onHandleSwitchChange } = props;

  return (
    <div>
      {
        modules.length > 0 && modules.map((module: any) => (
          <Accordion key={module?.module} sx={{ backgroundColor: '#d3d3d3', margin: '12px' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                <Stack direction="column" spacing={1}>
                  <Typography sx={{ fontWeight: '600' }}>
                    {module?.module}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#2559C3' }}>
                    Sub-Module
                    {` (${module?.subModules.length})`}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent Accordion from opening
                  }}
                >
                  {Object.keys(module?.permissions).map((permission: any) => (
                    <>
                      <Switch
                        name={permission}
                        value={module?.permissions[permission]}
                        color="success"
                        checked={!!module?.permissions[permission]}
                        onChange={(event) => onHandleSwitchChange(event, 'modules', module?.module)}
                      />
                      <FormLabel className="mr-2">{permission}</FormLabel>
                    </>
                  ))}
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {
                module?.subModules && module?.subModules.map((subModule: any) => (
                  <Stack key={subModule?.subModule} direction="row" justifyContent="space-between" p={2}>
                    <Typography>
                      {subModule?.subModule}
                    </Typography>
                    <Stack direction="row" alignItems="center">
                      {Object.keys(subModule?.permissions).map((permission) => (
                        <>
                          <Switch
                            color="success"
                            name={permission}
                            value={subModule?.permissions[permission]}
                            checked={subModule?.permissions[permission]}
                            onChange={(event) => onHandleSwitchChange(event, 'subModules', module?.module, subModule?.subModule)}
                          />
                          <FormLabel className="mr-2">{permission}</FormLabel>
                        </>
                      ))}
                    </Stack>
                  </Stack>
                ))
              }
            </AccordionDetails>
          </Accordion>
        ))
      }
    </div>
  );
};
export default AccordionTree;
