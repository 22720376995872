import React from 'react';
import {
  AppBar, Grid, Typography,
} from '@mui/material';
import {
  KeyboardArrowDown,
} from '@mui/icons-material';
import { Image } from '../components/Image';
import { Icon } from '../components/Icon';
import { Avatar } from '../components/Avatar';

const baseUrl = window.location.origin;
const imageLogo = `${baseUrl}/image 5.png`;
const Header = () => (
  <AppBar
    position="fixed"
    sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      zIndex: (theme) => theme.zIndex.drawer + 1,
      backgroundColor: '#fff',
      padding: '10px',
      boxShadow: 'none',
      borderBottom: '1px solid #EEEEEE',
    }}
  >
    <Grid item xs={6} md={6}>
      <Image src={imageLogo} alt="Successive logo" />
    </Grid>
    <Grid display="flex" item xs={6} md={6} justifyContent="end">
      <Avatar alt="Avatar.png" src={`${baseUrl}/Ellipse 2.png`} />
      <Grid pl={1} className="pr-3">
        <Typography variant="body1">
          ADMIN
        </Typography>
        <Typography style={{ color: '#2559C3' }}>Admin</Typography>
      </Grid>
      <div className="d-none">
        <Icon sx={{ color: '#2559C3' }}>
          <KeyboardArrowDown sx={{ fontSize: '25px' }} />
        </Icon>
      </div>
    </Grid>
  </AppBar>

);
export default Header;

