import React from 'react';
import { IconButton } from '@mui/material';
import { IconProps } from './type';

const Icon = (props: IconProps) => {
  const { children, ...rest } = props;
  return (
    <IconButton {...rest}>
      {children}
    </IconButton>
  );
};

export default Icon;
