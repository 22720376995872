import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#63b8ff',
      main: '#2559C3',
      dark: '#005db0',
      contrastText: '#000',
    },
    secondary: {
      main: '#4db6ac',
      light: '#82e9de',
      dark: '#00867d',
      contrastText: '#000',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        cursor: 'pointer',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '12px 20px',
          color: '#fff',
        },
      },
    },
  },
  // typography: {
  //   body2: {
  //     fontSize: '1.1 rem',
  //   },
  // },
});

export default theme;
