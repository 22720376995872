import React, { useEffect, useState } from 'react';
import {
  Button,
  Tooltip,
  Stack,
  FormLabel,
  TextField,
  Typography,
  // IconButton,
  Box,
  Grid,
  Paper,
  // Avatar,
} from '@mui/material';
import axios from 'axios';
import InfoIcon from '@mui/icons-material/Info';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreateModule } from './type';
import { Severity } from '../../components/Toast/type';
import { Loader, Toast } from '../../components';

type IModule = {
  id: string;
  name: string;
  code: string;
};

const initialModuleState: IModule = { id: '', name: '', code: '' };

const FormModal = ({
  moduleEdit,
}: any) => {
  const location = useLocation();
  const [module, setModule] = useState<IModule>(initialModuleState);
  const navigate = useNavigate();
  const [disable, setDisable] = useState<boolean>(true);
  const [error, setError] = useState<{ [key: string]: boolean }>({});
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<{ message: string, status: number, open: boolean, severity: Severity }>({
    message: '', status: 500, severity: Severity.ERROR, open: false,
  });
  const customStyles = {
    width: '100%',
    overflow: 'hidden',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '8px !important', // Note: Property name should be camelCase
    fontWeight: 500,
  };

  useEffect(() => {
    let isDisabled;
    if (isEdit) {
      isDisabled = module?.code?.length > 0 && module?.name?.length > 0;
    } else {
      isDisabled = module?.code?.length > 0 && module?.name?.length > 0;
    }
    if (location.state && module.name) {
      const { editData } = location.state;
      isDisabled = module?.name !== editData?.name;
    }
    setDisable(!isDisabled);
  }, [module, isEdit, location.state]);

  const addModules = async (formData: CreateModule) => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/module`, formData);
      if (data.error) {
        setInfo({
          ...info, message: data.message, open: true, severity: Severity.ERROR,
        });
        setLoading(false);
      } else {
        const { message } = data;
        setInfo({
          ...info, message, open: true, severity: Severity.SUCCESS,
        });
        setTimeout(() => {
          navigate('/module');
          setLoading(false);
        }, 2000);
      }
    } catch (err: any) {
      setInfo({
        open: true,
        message: err.response.data.message,
        status: err.response.status,
        severity: Severity.ERROR,
      });
      setLoading(false);
    }
  };

  const editModules = async (formData: CreateModule) => {
    try {
      setLoading(true);
      const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/module`, formData);
      if (data.error) {
        setInfo({
          ...info, message: data.message, open: true, severity: Severity.ERROR,
        });
        setLoading(false);
      } else {
        const { message } = data;
        setInfo({
          ...info, message, open: true, severity: Severity.SUCCESS,
        });
        setTimeout(() => {
          navigate('/module');
          setLoading(false);
        }, 2000);
      }
    } catch (err: any) {
      setInfo({
        open: true,
        message: err.response.data.message,
        status: err.response.status,
        severity: Severity.ERROR,
      });
      setLoading(false);
    }
  };

  const handleEdit = async (id: string) => {
    try {
      // setOpen(true);
      setIsEdit(true);
      const { data: { data = {} } = {} } = await axios.get(`${process.env.REACT_APP_API_URL}/module/${id}`);
      setModule(data);
    } catch (err: any) {
      setInfo({
        open: true,
        message: err.response.data.message,
        status: err.response.status,
        severity: Severity.ERROR,
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    // When moduleEdit changes (probably when the edit button is clicked), update the local state
    if (location.state) {
      const { editData } = location.state;
      handleEdit(editData.id);
    }
  }, [location.state, moduleEdit]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setError((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === '',
    }));
    setModule({
      ...module,
      [name]: name === 'code' ? value.toLocaleUpperCase() : value,
    });
  };

  const handleBack = () => {
    navigate('/module?param=0');
  };

  const handleUpdate = (dataToUpdate: any) => {
    setLoading(true);
    if (isEdit) {
      editModules(dataToUpdate);
    } else {
      addModules(dataToUpdate);
    }
  };
  const EditModule = () => {
    handleUpdate(module);
  };
  const AddModule = () => {
    handleUpdate(module);
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return isEdit ? EditModule() : AddModule();
  };

  return (
    <Grid item lg={12} xs={12} sm={12}>
      {loading && <Loader open={loading} />}
      {info.open && (
        <Toast
          open={info.open}
          message={info.message}
          severity={info.severity}
          onClose={() => setInfo({ ...info, open: false })}
        />
      )}
      <Paper sx={customStyles}>
        <Box mt={2} sx={{ background: 'white' }} p={4}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">
              Add Module
            </Typography>
          </Stack>
          <Box component="form" onSubmit={handleSubmit} my={2}>
            <Stack spacing={2}>
              <Stack direction="row" alignItems="center">
                <FormLabel
                  sx={{
                    fontSize: '1rem',
                    fontWeight: '900',
                    m: '0',
                    lineHeight: '2rem',
                    color: '#000',
                  }}
                  required
                >
                  Name
                </FormLabel>
                <Tooltip title="Name is required" sx={{ marginLeft: '0.5rem' }}>
                  <InfoIcon />
                </Tooltip>
              </Stack>
              <TextField
                error={error.name}
                fullWidth
                name="name"
                id="outlined-basic"
                variant="outlined"
                helperText={error.name ? 'Please enter module name' : ''}
                onChange={handleChange}
                value={module?.name}
                inputProps={{ maxLength: 30 }}
              />
              <Stack direction="row" alignItems="center">
                <FormLabel
                  sx={{
                    fontSize: '1rem',
                    fontWeight: '900',
                    color: '#000',
                  }}
                  required
                >
                  Code
                </FormLabel>
                <Tooltip title="Code is required" sx={{ marginLeft: '0.5rem' }}>
                  <InfoIcon />
                </Tooltip>
              </Stack>
              <TextField
                error={error?.code}
                name="code"
                fullWidth
                inputProps={{
                  readOnly: !!isEdit,
                  length: 15,
                  maxLength: 30,
                }}
                id="outlined-basic"
                variant="outlined"
                helperText={error?.code ? 'Please enter module code' : ''}
                onChange={handleChange}
                value={module?.code}
              />
              <Stack direction="row" justifyContent="space-between">
                <Button onClick={handleBack} variant="contained">
                  Back
                </Button>
                <Button disabled={disable} type="submit" variant="contained">
                  {isEdit ? 'Update' : 'Submit'}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default FormModal;
