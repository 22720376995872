import React from 'react';
import { Avatar as MUIAvatar } from '@mui/material';
import { AvatarProps } from './type';

const Avatar = (props: AvatarProps) => {
  const { children, ...rest } = props;
  return (
    <MUIAvatar {...rest}>{children}</MUIAvatar>
  );
};

export default Avatar;
